import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["link"]

  static values = {
    content: String
  }

  // Update event listener (doc-back-link:update)
  //
  // If we are receiving an event on an empty turbo-frame (which has
  // not yet been loaded) while navigating the child link target
  // will not be present.
  //
  // In that case the update event is fired again in the doc-frame controller when the
  // frame has finished to render (in doc-frame#afterRender).
  update() {
    if (this.hasLinkTarget) {
      if (this.hasContentValue) {
        this.linkTarget.href = this.contentValue
      } else {
        this.linkTarget.removeAttribute("href")
      }
    }
  }

  // remember event listener (doc-back-link:remember)
  //
  // Store the event detail (a back URL) for later usage
  remember(event) {
    if (event.detail?.backUrl) {
      this.contentValue = event.detail.backUrl
    } else {
      // Remove the content value
      this.contentValue = undefined
    }
  }
}
