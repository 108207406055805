/*
  This function is directly linked to the routes published by the
  backend server. This is why it's defined as a “utils” function to
  avoid duplicating this logic in multiple stimulus controllers.

  @input url: URL
  @returns nodeId: String
  */
export function nodeIdFromUrl(url) {
  let parts = url.pathname.split(/\/(group|operation|topic|model)\//)
  parts.push(url.hash.slice(1))
  parts = parts.filter(element => element !== undefined && element !== "")
  let nodeId = parts.pop()

  if (nodeId.endsWith("/authentication") || nodeId === "topic-authentication") {
    // The second condition '|| nodeId === "topic-authentication"' is
    // for backwards compatibility when x-topic authentication
    // paragraphs would have that URL (it's not the case anymore).
    //
    // We target the specific “fake” node for the Authentication
    // paragraph.
    nodeId = "doc-authentication"
  } else if (nodeId.endsWith("/bindings")) {
    // or the Bindings paragraph
    nodeId = "protocol-information"

  // If there's no more elements in the parts, it's because we are
  // probably at the “root”
  } else if (!parts.length) {
    nodeId = "doc-introduction"
  }

  return nodeId
}

export function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false
    }
  }

  return true
}

export function capitalize(str) {
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

// following https://stackoverflow.com/questions/246801/how-can-you-encode-a-string-to-base64-in-javascript/53221307#53221307,
// this base64 object will be us ton encode and decode object to string with base64 format,
// supporting characters outside of Ascii range (user may fill Explorer form with emoji, 💩 happens).
const base64Utils = {
  decode: s => Uint8Array.from(atob(s), c => c.charCodeAt(0)),
  encode: b => btoa(String.fromCharCode(...new Uint8Array(b))),
}

export const base64 = {
  decodeToString: s => new TextDecoder().decode(base64Utils.decode(s)),
  encodeString: s => base64Utils.encode(new TextEncoder().encode(s)),
}

