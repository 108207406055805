import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["button", "response", "dialog"]

  expand() {
    const response = this.responseTarget.cloneNode(true)
    const closeButton = document.importNode(this.buttonTarget.content, true)
    response.querySelector("header").appendChild(closeButton)
    response.removeAttribute("data-response-dialog-target")
    this.dialogTarget.replaceChildren(response)
  }
}
